<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
       <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="用户昵称" prop="nickName" >
        <a-input disabled v-model="form.nickName" placeholder="请输入用户昵称" />
      </a-form-model-item>
      <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="电话" prop="moblie" >
        <a-input disabled v-model="form.mobile" placeholder="请输入用户电话" />
      </a-form-model-item>
       <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="举报类型" prop="labelTypeName" >
        <a-input disabled v-model="form.labelTypeName" placeholder="请输入举报类型" />
      </a-form-model-item>
       <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="被举报业务类型" prop="reportType" >
        <a-select disabled placeholder="请选择被举报业务类型" v-model="form.reportType">
          <a-select-option value="1">剧本</a-select-option>
          <a-select-option value="2">动态</a-select-option>
          <a-select-option value="3">评论</a-select-option>
          <a-select-option value="4">店铺</a-select-option>
          <a-select-option value="5">剧本集</a-select-option>
          <a-select-option value="6">用户</a-select-option>
        </a-select>
      </a-form-model-item>
       <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="被举报业务" prop="reportTitle" >
        <a-input disabled v-model="form.reportTitle" type="textarea"/>
      </a-form-model-item>
       <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="反馈内容" prop="content" >
        <a-input disabled v-model="form.content" type="textarea"/>
      </a-form-model-item>
       <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="图片证据" prop="img" >
<!--        <a-input v-model="form.img" placeholder="请输入图片证据" />-->
         <img v-for="img in form.img" :src='img' style='height:150px;max-width:150px'/>
      </a-form-model-item>
       <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="备注" prop="remark" >
        <a-input disabled v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <div class="bottom-control" v-show="showButton">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getReportRecord, addReportRecord, updateReportRecord } from '@/api/report/reportRecord'
import Editor from '@/components/Editor'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    Editor
  },
  data () {
    return {
      showButton: true,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        userId: null,
        nickName: null,
        mobile:null,
        labelTypeName: null,
        reportTitle: null,
        labelType: null,

        reportType: null,

        reportId: null,

        content: null,

        img: null,

        modifier: null,

        createTime: null,

        updateTime: null,

        isDeleted: null,

        remark: null,

        version: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        userId: [
          { required: true, message: '外键 user_user.id不能为空', trigger: 'blur' }
        ],

        labelType: [
          { required: true, message: '反馈标签类型不能为空', trigger: 'change' }
        ],

        reportType: [
          { required: true, message: '举报内容类型 1:剧本,2:动态,3:评论,4:店铺,5:剧本集,6:用户不能为空', trigger: 'change' }
        ],

        reportId: [
          { required: true, message: '反馈类型不能为空', trigger: 'blur' }
        ],

        content: [
          { required: true, message: '反馈内容不能为空', trigger: 'blur' }
        ],

        img: [
          { required: true, message: '图片证据不能为空', trigger: 'blur' }
        ],

      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,

        userId: null,
        nickName: null,
        mobile:null,
        labelTypeName: null,
        reportTitle: null,
        labelType: null,

        reportType: null,

        reportId: null,

        content: null,

        img: null,

        modifier: null,

        createTime: null,

        updateTime: null,

        isDeleted: null,

        remark: null,

        version: null

      }
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, type) {
      if (type == 1) {
        this.showButton = true
      }
      if (type == 2) {
        this.showButton = false
      }
      this.reset()
      this.formType = 2
      const id = row.id
      getReportRecord(id).then(response => {
        this.form = response.data
        if(response.data.img) {
          let img = response.data.img.split(",");
          this.form.img = img;
        }
        this.open = true
        if (type == 1) {
          this.formTitle = '修改'
        }
        if (type == 2) {
          this.formTitle = '详情'
        }
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {

            if (this.form.img instanceof Array) {
              this.form.img = this.form.img.join(",");
            }

            updateReportRecord(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addReportRecord(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
